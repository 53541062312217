import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LikertVariable } from '@/types/api';

@Component
export default class Likert extends Vue {
    @Prop()
    variable!: LikertVariable;

    @Prop()
    answer: any;

    value: any = null;

    @Watch('value')
    valueChanged(val: unknown) {
        if (typeof val === 'number') {
            this.$emit('input', {
                answer_type: this.variable.answer_type,
                variable: this.variable.id,
                value: this.value,
            });
        }
    }

    created() {
        if (this.answer) {
            this.value = this.answer.value;
        }

        window.addEventListener('keyup', this.keypress);
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.keypress);
    }

    keypress(event: KeyboardEvent) {
        const number = Number(event.key);

        if (number !== NaN && number > 0 && number <= this.variable.choices.length) {
            this.value = number - 1;
        }
    }
}
