/**
 * Gebaseerd op kopie uit misscion control centre 2
 */

import fetchBase from './fetchBase';

import { apiUrl } from '@/env';

export default async (url: string, init?: RequestInit) => {
    if (init && init.method && ['post', 'put'].includes(init.method)) {
        if (!init.headers) {
            init.headers = {};
        }

        Object.assign(init.headers, {
            'Content-Type': 'application/json',
        });
    }

    const response = await fetchBase(apiUrl + url, init);

    if (response.status === 204) {
        return new Promise<any>((resolve) => { resolve(); });
    } else if (response.status === 400) {
        throw await response.json();
    } else if (response.status > 400) {
        throw new Error(`Got status code ${response.status} from server`);
    } else {
        return response.json();
    }
};
