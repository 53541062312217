import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';

@Component
export default class Tray extends Vue {
    @State((state) => state.tray.messages)
    messages!: string[];

    @Mutation('discardMessage')
    discard: any;

    @Mutation
    discardMessages: any;

    classList = {
        default: '',
        error: 'is-danger',
        success: 'is-success',
    };

    iconList = {
        default: 'mdi-information-outline',
        error: 'mdi-alert',
        success: 'mdi-information-outline',
    };

    @Watch('$route')
    routeChanged() {
        // this.discardMessages();
    }
}
