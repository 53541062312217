import { Component, Vue, Prop } from 'vue-property-decorator';
import { Schema } from '@/components/smartForm/types';
import mcFetch from '@/api/mcFetch';

import SmartForm from '@/components/smartForm/smartForm.vue';
import { Action } from 'vuex-class';

@Component({
    components: {
        SmartForm,
    },
})
export default class Reset extends Vue {
    @Action
    addSuccess: any;

    @Prop()
    uid!: string;

    @Prop()
    token!: string;

    schema: Schema = [
        {
            name: 'new_password1',
            label: 'Nieuw wachtwoord',
            type: 'password',
            icon: 'mdi-key',
        },
        {
            name: 'new_password2',
            label: 'Nieuw wachtwoord herhalen',
            type: 'password',
            icon: 'mdi-key',
        },
    ];

    data: any = {};
    errors: any = {};

    loading = false;
    success = false;
    error = false;

    async submit() {
        this.loading = true;
        this.success = false;
        this.error = false;
        this.errors = {};

        try {
            await mcFetch('/auth/password/reset/confirm', {
                method: 'post',
                body: JSON.stringify({
                    ...this.data,
                    uid: this.uid,
                    token: this.token,
                }),
            });

            this.success = true;
            this.addSuccess('Nieuw wachtwoord ingesteld');
            this.$router.push({
                name: 'login',
            });
        } catch (e) {
            this.error = true;
            if (e.message) {
                throw new Error('Door een onbekende fout kon dit formulier niet worden verstuurd.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                if (e.token || e.uid) {
                    throw new Error('Deze link is verlopen. Vraag opnieuw een nieuw wachtwoord op');
                }

                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
