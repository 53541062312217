import { Component, Vue } from 'vue-property-decorator';
import mcFetch from '@/api/mcFetch';

@Component
export default class Inquiry extends Vue {
    inquiry: Inquiry | null = null;

    exists = true;

    async mounted() {
        await this.loadData();
    }

    async loadData() {
        this.exists = true;

        try {
            const inquiry = await mcFetch(`/datacollection/inquiries/${this.$route.params.token}`);

            if (inquiry.deadline !== null) {
                const deadline = new Date(inquiry.deadline);
                const now = new Date();
                now.setHours(0,0,0,0);

                if (deadline < now) {
                    throw new Error('Vragenlijst verlopen')
                }
            }

            this.inquiry = inquiry;
        } catch (e) {
            this.exists = false;
        }
    }
}
