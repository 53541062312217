import { Component, Vue, Prop } from 'vue-property-decorator';
import { Variable } from '@/types/api';

@Component
export default class Interval extends Vue {
    @Prop()
    variable!: Variable;

    @Prop()
    answer: any;

    value: any = null;

    created() {
        if (this.answer) {
            this.value = this.answer.value;
        } else {
            this.value = this.variable.min;
        }
    }

    update(event: any) {
        this.value = parseInt(event.target.value, 10);

        this.$emit('input', {
            answer_type: this.variable.answer_type,
            variable: this.variable.id,
            value: this.value,
        });
    }
}
