import { Component, Vue, Prop } from 'vue-property-decorator';
import { InquiryListing } from '@/types/api';
import mcFetch from '@/api/mcFetch';

@Component
export default class End extends Vue {
    @Prop()
    inquiry!: InquiryListing;

    created() {
        this.complete();
    }

    async complete() {
        try {
            await mcFetch(`/datacollection/inquiries/${this.$route.params.token}/completed`, {
                method: 'PATCH',
            });
        } catch (e) {
            throw new Error('De vragenlijst kon niet worden gesloten.');
        }
    }
}
