import Vue from 'vue';

import Account from '@mdi/Account.vue';
import AccountMultiple from '@mdi/AccountMultiple.vue';
import At from '@mdi/At.vue';
import Alert from '@mdi/Alert.vue';
import Cart from '@mdi/Cart.vue';
import Close from '@mdi/Close.vue';
import CalendarClock from '@mdi/CalendarClock.vue';
import Domain from '@mdi/Domain.vue';
import Delete from '@mdi/Delete.vue';
import Download from '@mdi/Download.vue';
import Play from '@mdi/Play.vue';
import ProgressCheck from '@mdi/ProgressCheck.vue';
import Reload from '@mdi/Reload.vue';
import Key from '@mdi/Key.vue';
import InformationOutline from '@mdi/InformationOutline.vue';

Vue.component('mdi-account', Account);
Vue.component('mdi-account-multiple', AccountMultiple);
Vue.component('mdi-at', At);
Vue.component('mdi-alert', Alert);
Vue.component('mdi-cart', Cart);
Vue.component('mdi-close', Close);
Vue.component('mdi-calendar-clock', CalendarClock);
Vue.component('mdi-domain', Domain);
Vue.component('mdi-delete', Delete);
Vue.component('mdi-download', Download);
Vue.component('mdi-play', Play);
Vue.component('mdi-progress-check', ProgressCheck);
Vue.component('mdi-reload', Reload);
Vue.component('mdi-key', Key);
Vue.component('mdi-information-outline', InformationOutline);

import ChevronRight from '@mdi/ChevronRight.vue';

Vue.component('mdi-chevron-right', ChevronRight);
