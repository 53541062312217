import { Component, Vue } from 'vue-property-decorator';
import { InquiryListing } from '@/types/api';
import mcFetch from '@/api/mcFetch';
import Card from './inquiry/_card/card.vue';

@Component({
    components: {
        Card,
    },
})
export default class Inquiries extends Vue {
    inquiries: InquiryListing[] = [];

    async mounted() {
        await this.loadData();
    }

    get chunckedInquiries() {
        return this.inquiries.reduce((prev, curr) => {
            if (prev[prev.length - 1].length < 4) {
                prev[prev.length - 1].push(curr);
            } else {
                prev.push([
                    curr,
                ]);
            }

            return prev;
        }, [[]] as InquiryListing[][]);
    }

    async loadData() {
        try {
            this.inquiries = await mcFetch('/auth/me/inquiries');
        } catch (e) {
            throw new Error('Kan vragenlijst niet ophalen');
        }
    }
}
