import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import NotFound from '@/views/notfound/notfound.vue';
import Invite from './views/invite/invite.vue';
import Login from './views/login/login.vue';
import Inquiries from './views/inquiries/inquiries.vue';
import Inquiry from './views/inquiries/inquiry/inquiry.vue';
import Start from './views/inquiries/inquiry/start/start.vue';
import Question from './views/inquiries/inquiry/questions/questions.vue';
import End from './views/inquiries/inquiry/end/end.vue';
import Root from './views/root/root.vue';
import Profile from './views/profile/profile.vue';
import Forgot from './views/forgot/forgot.vue';
import Reset from './views/reset/reset.vue';

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    routes: [
        {
            name: 'invite',
            path: '/inquiry/:token',
            component: Invite,
            props: true,
            meta: {
                public: true,
            },
        },
        {
            name: 'login',
            path: '/login',
            component: Login,
            meta: {
                public: true,
            },
        },
        {
            name: 'forgot',
            path: '/password-forgot',
            component: Forgot,
            meta: {
                public: true,
            },
        },
        {
            name: 'reset',
            path: '/password-reset/:uid/:token',
            component: Reset,
            props: true,
            meta: {
                public: true,
            },
        },
        {
            path: '/inquiries/:token/',
            component: Inquiry,
            children: [
                {
                    name: 'inquiry',
                    path: '',
                    component: Start,
                },
                {
                    name: 'end',
                    path: 'end',
                    component: End,
                },
                {
                    name: 'questions',
                    path: 'questions/:id',
                    component: Question,
                    props: (route) => ({
                        id: parseInt(route.params.id, 10),
                    }),
                },
            ],
        },
        {
            name: 'inquiries',
            path: '/inquiries/',
            component: Inquiries,
        },
        {
            name: 'profile',
            path: '/profile',
            component: Profile,
        },
        {
            name: 'root',
            path: '/',
            component: Root,
        },
        {
            name: '404',
            path: '*',
            component: NotFound,
            meta: {
                public: true,
            },
        },
    ],
});
