import { Component, Vue, Prop } from 'vue-property-decorator';
import { Schema, Field } from './types';

import Input from './fields/input.vue';

@Component({
    components: {
        SmartInput: Input,
    },
})
export default class SmartForm extends Vue {
    @Prop()
    schema!: Schema;

    @Prop()
    errors!: any;

    @Prop({default: {}})
    value!: any;

    get fields() {
        const fields: Array<{
            component: string;
            field: Field,
        }> = [];

        for (const field of this.schema) {
            fields.push({
                component: this.fieldMapper(field),
                field,
            });
        }

        return fields;
    }

    fieldMapper(field: Field) {
        switch (field.type) {
            default:
                return 'smart-input';
        }
    }

    input(fieldname: string, value: any) {
        this.$emit('input', {
            ...this.value,
            [fieldname]: value,
        });
    }
}
