import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import { Schema } from '@/components/smartForm/types';
import SmartForm from '@/components/smartForm/smartForm.vue';
import { Info } from '@/types/api';

@Component({
    components: {
        SmartForm,
    },
})
export default class Register extends Vue {
    schema: Schema = [
        {
            name: 'email',
            label: 'E-mailadres',
            type: 'e-mail',
            required: true,
            icon: 'mdi-at',
        },
        {
            name: 'password1',
            label: 'Wachtwoord',
            type: 'password',
            required: true,
            icon: 'mdi-key',
        },
        {
            name: 'password2',
            label: 'Wachtwoord herhalen',
            type: 'password',
            required: true,
            icon: 'mdi-key',
        },
    ];

    data: any = {};
    errors: any = {};

    @Prop()
    metadata!: Info;

    @Prop()
    token!: string;

    @Action
    register!: (payload: any) => Promise<void>;

    loading = false;

    mounted() {
        // this.data.email = this.metadata.email;
        this.$set(this.data, 'email', this.metadata.email);
    }

    async submit() {
        this.loading = true;

        try {
            await this.register({
                ...this.data,
                token: this.token,
            });

            this.$router.push({
                name: 'inquiry',
                params: {
                    token: this.token,
                },
            });
        } catch (e) {
            if (e.message) {
                throw new Error('Registratie mislukt, probeer het later nog eens.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
