import { Module } from 'vuex';

export interface IMessage {
    type: 'default' | 'error' | 'success' | 'warning';
    message: string;
    duration?: number;
}

const moduleState = {
    messages: [] as IMessage[],
    discarded: [] as IMessage[],
};

export default {
    state: moduleState,
    getters: {
    },
    mutations: {
        addMessage(state, message: IMessage) {
            state.messages.push(message);
        },
        // addWarning(state, message: string) {
        //     state.messages.push({
        //         type: 'warning',
        //         message,
        //     });
        // },
        // addError(state, message: string) {
        //     state.messages.push({
        //         type: 'error',
        //         message,
        //     });
        // },
        // addSuccess(state, message: string) {
        //     state.messages.push({
        //         type: 'success',
        //         message,
        //     });
        // },
        discardMessage(state, message: IMessage) {
            // state.discarded.push(
            //     state.messages.splice(index, 1).pop() as IMessage,
            // );
            if (state.messages.includes(message)) {
                state.discarded.push(
                    state.messages.splice(
                        state.messages.indexOf(message),
                        1,
                    ).pop() as IMessage,
                )
            }
        },
        discardMessages(state) {
            state.discarded.push(
                ...state.messages.splice(0),
            );
        },
    },
    actions: {
        addError({ dispatch }, message: string) {
            dispatch('addMessage', {
                type: 'error',
                message,
                duration: 4000,
            });
        },
        addSuccess({ dispatch }, message: string) {
            dispatch('addMessage', {
                type: 'success',
                message,
                duration: 4000,
            });
        },
        addMessage({ commit }, message: IMessage) {
            commit('addMessage', message);

            if (message.duration) {
                window.setTimeout(() => {
                    commit('discardMessage', message)
                }, message.duration);
            }
        }
    }
} as Module<typeof moduleState, any>;
