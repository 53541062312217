import { Module } from 'vuex';
import { apiUrl } from '@/env';
import mcFetch from '@/api/mcFetch';
import { User } from '@/types/api';

interface RegisterPayload {
    token: string;
    email: string;
    password1: string;
    password2: string;
}

interface LoginPayload {
    email: string;
    password: string;
}

export const userState = {
    token: null as string | null,
    user: null as User | null,
};

export default {
    state: userState,
    getters: {
        authorized: (state) => {
            return state.token && state.user;
        },
    },
    mutations: {
        setToken(state, payload: string) {
            state.token = payload;
        },
        setUser(state, payload: any) {
            state.user = Object.freeze(payload);
        },
        delUser(state) {
            state.token = null;
            state.user = null;
        },
    },
    actions: {
        async fetchUser({ state, commit }) {
            if (state.token) {
                const response = await mcFetch('/auth/me');

                commit('setUser', response);
            }
        },
        async login({ commit, dispatch }, payload: LoginPayload) {
            const response = await fetch(apiUrl + '/auth/login', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.status === 400) {
                throw await response.json();
            } else if (!response.ok) {
                throw new Error('Response login api not ok');
            }

            const data = await response.json();

            commit('setToken', data.key);
            await dispatch('fetchUser');
        },
        async register({ commit, dispatch }, payload: RegisterPayload) {
            const response = await fetch(apiUrl + '/auth/register', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.status === 400) {
                throw await response.json();
            } else if (!response.ok) {
                throw new Error('Response register api not ok');
            }

            const data = await response.json();

            commit('setToken', data.key);
            await dispatch('fetchUser');
        },
    },
} as Module<typeof userState, unknown>;
