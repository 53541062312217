import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Variable, Answer, NominalAnswer } from '@/types/api';

@Component
export default class MultipleChoice extends Vue {
    @Prop()
    variable!: Variable;

    @Prop()
    answer!: Answer<NominalAnswer>;

    value: NominalAnswer = [];

    @Watch('value')
    valueChanged(val: string[]) {
        if (typeof val === 'object') {
            if (val.length > 0 && this.variable.max_choices !== undefined && val.length <= this.variable.max_choices) {
                this.$emit('input', {
                    answer_type: this.variable.answer_type,
                    variable: this.variable.id,
                    value: this.value,
                });
            } else {
                this.$emit('input', null);
            }
        }
    }

    created() {
        if (this.answer) {
            this.value = this.answer.value;
        }
    }
}
