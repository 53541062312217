import { Component, Vue } from 'vue-property-decorator';

import User from './_user/user.vue';
import Password from './_password/password.vue';

@Component({
    components: {
        User,
        Password,
    },
})
export default class Profile extends Vue {

}
