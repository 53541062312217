import { Component, Vue } from 'vue-property-decorator';
import { Getter, State, Mutation, Action } from 'vuex-class';

@Component
export default class Navbar extends Vue {
    @Getter
    authorized!: boolean;

    @State((state) => state.user.user)
    user: any;

    @Mutation
    delUser!: () => void;

    @Action
    addSuccess!: (msg: string) => void;

    logout() {
        if (confirm('Weet je zeker dat jij je af wilt melden?')) {
            this.delUser();
            this.$router.push({
                name: 'login',
            });

            this.addSuccess('Je bent nu uitgelogd');
        }
    }
}
