import { Component, Vue, Prop } from 'vue-property-decorator';
import Register from './_register/register.vue';
import mcFetch from '@/api/mcFetch';
import { Info } from '@/types/api';
import { Mutation, Getter, Action } from 'vuex-class';

@Component({
    components: {
        Register,
    },
})
export default class Invite extends Vue {
    @Prop()
    token!: string;

    @Getter
    authorized!: boolean;

    @Mutation
    delUser!: () => void;

    @Action
    addSuccess!: (msg: string) => void;

    metadata: any = null;

    get sender() {
        return this.$route.query.organisation || 'Een organisatie';
    }

    async mounted() {
        await this.loadData();
    }

    async loadData() {
        try {
            const metadata: Info = await mcFetch(`/auth/info/${this.token}`);

            if (metadata.has_account && metadata.is_authenticated) {
                this.$router.push({ name: 'inquiry', params: { token: this.token } });
            } else {
                if (this.authorized) {
                    this.delUser();
                }

                if (metadata.has_account) {
                    this.$router.push({ name: 'login' });
                    this.addSuccess(`Log in met ${metadata.email} om verder te gaan`);
                } else {
                    this.metadata = metadata;
                }
            }
        } catch (e) {
            throw new Error('Deze uitnodiging kon niet worden gevonden.');
        }
    }
}
