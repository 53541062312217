import Vue from 'vue';
import App from './app/app.vue';
import router from './router';
import store from './store/store';

// Import styles
import './style/main.scss';

import './icons';
import './filters';

// Import bulmalite componetns
import 'bulmalite/lib/components/loading';
import 'bulmalite/lib/components/navbar';

// Analytics
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
    config: {
        id: 'UA-134167877-3',
    },
}, router);

Vue.config.productionTip = false;


Vue.config.errorHandler = (err, vm, info) => {
    store.dispatch('addError', err.message);
};

router.beforeEach((to, from, next) => {
    if (!to.meta.public && !store.getters.authorized) {
        next({
            name: 'login',
            query: {
                next: encodeURI(window.location.pathname),
            },
        });
    } else {
        next();
    }
});

(async () => {
    if (store.state.user.token) {
        try {
            await store.dispatch('fetchUser');
        } catch (e) {
            store.commit('delUser');
        }
    }

    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
})();
