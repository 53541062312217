import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Navbar from '@/components/navbar/navbar.vue';
import Tray from '@/components/tray/tray.vue';

@Component({
    components: {
        Navbar,
        Tray,
    },
})
export default class App extends Vue {
    @Getter
    authorized!: boolean;
}
