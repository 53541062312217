import { Component, Vue, Prop } from 'vue-property-decorator';
import { Variable, Answer, OrdinalAnswer } from '@/types/api';

@Component
export default class Distribution extends Vue {
    @Prop()
    variable!: Variable;

    @Prop()
    answer!: Answer<OrdinalAnswer>;

    value: number[] = [];

    get usage() {
        return (this.variable.points || 0) - this.value.reduce((previous, current) => previous + current);
    }

    created() {
        if (this.answer) {
            this.value = this.answer.value;
        } else if (this.variable.choices) {
            if (this.value.length > 0) {
                this.value.splice(0);
            }

            for (const question of this.variable.choices) {
                this.value.push(0);
            }
        }
    }

    update(i: number, value: string) {
        this.value.splice(i, 1, parseInt(value, 10));

        this.$emit('input', {
            answer_type: this.variable.answer_type,
            variable: this.variable.id,
            value: this.value,
        });
    }
}
