import { Component, Vue } from 'vue-property-decorator';
import { Schema } from '@/components/smartForm/types';
import SmartForm from '@/components/smartForm/smartForm.vue';
import { Action, Getter } from 'vuex-class';

@Component({
    components: {
        SmartForm,
    },
})
export default class Login extends Vue {
    @Action
    login!: (payload: any) => Promise<void>;

    @Getter
    authorized!: boolean;

    @Action
    addSuccess!: (msg: string) => void;

    schema: Schema = [
        {
            name: 'email',
            label: 'E-mailadres',
            type: 'e-mail',
            icon: 'mdi-at',
        },
        {
            name: 'password',
            label: 'Wachtwoord',
            type: 'password',
            icon: 'mdi-key',
        },
    ];

    data: any = {};
    errors: any = {};

    loading = false;

    created() {
        if (this.authorized) {
            this.$router.push({
                name: 'inquiries',
            });
        }
    }

    async submit() {
        this.loading = true;
        this.errors = {};

        try {
            await this.login(this.data);

            if (typeof this.$route.query.next === 'string') {
                this.$router.push(decodeURI(this.$route.query.next));
            } else {
                this.$router.push({
                    name: 'inquiries',
                });

                this.addSuccess('Je bent nu ingelogd');
            }
        } catch (e) {
            if (e.message) {
                throw new Error('Inloggen mislukt.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
