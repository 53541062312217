import { Component, Vue } from 'vue-property-decorator';

import SmartForm from '@/components/smartForm/smartForm.vue';
import { Schema } from '@/components/smartForm/types';
import mcFetch from '@/api/mcFetch';
import { Action } from 'vuex-class';

@Component({
    components: {
        SmartForm,
    },
})
export default class Forgot extends Vue {
    @Action
    addSuccess!: (msg: string) => void;

    schema: Schema = [
        {
            name: 'email',
            label: 'E-mailadres',
            type: 'e-mail',
            icon: 'mdi-at',
        },
    ];

    data: any = {};
    errors: any = {};

    loading = false;
    success = false;
    error = false;

    async submit() {
        this.loading = true;
        this.success = false;
        this.error = false;
        this.errors = {};

        try {
            await mcFetch('/auth/password/reset', {
                method: 'post',
                body: JSON.stringify(this.data),
            });

            this.success = true;
            this.addSuccess('Wij hebben een mailtje gestuurd met instructies');
        } catch (e) {
            this.error = true;
            if (e.message) {
                throw new Error('Door een onbekende fout kon dit formulier niet worden verstuurd.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
