import { Component, Vue, Prop } from 'vue-property-decorator';
import { Variable, NominalAnswer, Answer } from '@/types/api';

@Component
export default class Open extends Vue {
    @Prop()
    variable!: Variable;

    @Prop()
    answer!: Answer<NominalAnswer>;

    value: NominalAnswer = [''];

    created() {
        if (this.answer) {
            this.value = [...this.answer.value];
            this.addEmpty();
        }
    }

    remove(i: number) {
        this.value.splice(i, 1);

        this.emit();
        this.addEmpty();
    }

    update(i: number, value: string) {
        this.value[i] = value;

        this.emit();
        this.addEmpty();
    }

    addEmpty() {
        if (this.value[this.value.length - 1]) {
            this.value.push('');
        }
    }

    emit() {
        this.$emit('input', {
            answer_type: this.variable.answer_type,
            variable: this.variable.id,
            value: this.value.filter((item) => Boolean(item)),
        });
    }
}
