import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inquiry } from '@/types/api';

@Component
export default class Start extends Vue {
    @Prop()
    inquiry!: Inquiry;

    get firstQuestion() {
        return this.inquiry.answers.length;
    }

    get resumable() {
        return this.firstQuestion > 0;
    }

    get redoable() {
        return this.firstQuestion === this.inquiry.variables.length;
    }
}
