import { Component, Vue, Prop } from 'vue-property-decorator';
import { Field } from '../types';

@Component
export default class Input extends Vue {
    @Prop()
    schema!: Field;

    @Prop()
    value!: any;

    @Prop({default: null})
    error: any;
}
