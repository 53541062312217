import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inquiry, Variable } from '@/types/api';
import mcFetch from '@/api/mcFetch';

import Interval from '@/components/variables/interval/interval.vue';
import Likert from '@/components/variables/likert/likert.vue';
import MultipleChoice from '@/components/variables/multipleChoice/multipleChoice.vue';
import Distribution from '@/components/variables/distribution/distribution.vue';
import Open from '@/components/variables/open/open.vue';
import { Action } from 'vuex-class';

@Component({
    components: {
        Distribution,
        Interval,
        Likert,
        MultipleChoice,
        Open,
    },
})
export default class Question extends Vue {
    @Prop()
    id!: number;

    @Prop()
    inquiry!: Inquiry;

    @Action
    addSuccess!: (message: string) => void;

    enterName = 'slideRight-enter-active';
    leaveName = 'slideLeft-leave-active';

    loading = false;

    variable: Variable | null = null;
    component: string | null = null;
    answer: any = null;
    enterTip = true;

    @Watch('id')
    idChanged(val: number, old: number) {
        // Prevent skipping questions
        if (val > this.inquiry.answers.length) {
            this.$router.push({
                name: 'questions',
                params: {
                    ...this.$route.params,
                    id: String(this.inquiry.answers.length),
                },
            })
        }

        const variable = this.variable = this.inquiry.variables[val];

        if (!variable) {
            throw new Error('Variabele niet gevonden!');
        }

        if (val > old) {
            this.enterName = 'slideRight-enter-active';
            this.leaveName = 'slideLeft-leave-active';
        } else {
            this.enterName = 'slideLeft-enter-active';
            this.leaveName = 'slideRight-leave-active';
        }

        this.component = this.variableComponent(this.variable.resourcetype);
        this.answer = this.inquiry.answers.filter((value: any) => {
            return value.variable === variable.id;
        })[0] || null;
    }

    created() {
        this.idChanged(this.id, -1);

        window.addEventListener('keyup', this.keypress);
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.keypress);
    }

    update(answer: any) {
        this.answer = answer;
    }

    variableComponent(resourceType: string) {
        switch (resourceType) {
            case 'DistributionVariable':
                return 'distribution';
            case 'IntervalVariable':
                return 'interval';
            case 'LikertScaleVariable':
                return 'likert';
            case 'MultipleChoiceVariable':
                return 'multiple-choice';
            case 'OpenVariable':
                return 'open';
            default:
                console.error('Unknown question type', resourceType);
                throw new Error('Deze vraag is niet juist geconfigureerd!');
        }
    }

    back() {
        this.$router.push({
            name: 'questions',
            params: {
                ...this.$route.params,
                id: String(this.id - 1),
            },
        });
    }

    keypress(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.next()
        }
    }

    async next() {
        if (!this.answer) {
            return;
        }

        this.loading = true;

        try {
            const answer = await mcFetch(`/datacollection/inquiries/${this.$route.params.token}/answers`, {
                method: 'post',
                body: JSON.stringify(this.answer),
            });

            for (const exisiting of this.inquiry.answers.filter((value) => {
                return value.variable === answer.variable;
            })) {
                this.inquiry.answers.splice(
                    this.inquiry.answers.indexOf(exisiting),
                    1,
                );
            }

            this.inquiry.answers.push(answer);

            if (this.inquiry.variables[this.id + 1]) {
                this.enterTip = false;

                this.$router.push({
                    name: 'questions',
                    params: {
                        ...this.$route.params,
                        id: String(this.id + 1),
                    },
                });
            } else {
                this.$router.push({
                    name: 'end',
                });
            }
        } catch (e) {
            throw new Error('Door een storing kunnen wij dit antwoord niet opslaan');
        } finally {
            this.loading = false;
        }
    }
}
