import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import VuexPersistence from 'vuex-persist'

import tray from './tray';
import user from './user';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    reducer: (state: any) => ({ user: { token: state.user.token } }),

    // modules: [
    //     'user',
    // ],
});

export default new Vuex.Store({
    modules: {
        tray,
        user,
    },
    plugins: [
        vuexLocal.plugin,
    ],
}) as Store<any>;
