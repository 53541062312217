import { Component, Vue } from 'vue-property-decorator';

import SmartForm from '@/components/smartForm/smartForm.vue';
import { Schema } from '@/components/smartForm/types';
import mcFetch from '@/api/mcFetch';
import { Action } from 'vuex-class';

@Component({
    components: {
        SmartForm,
    },
})
export default class Password extends Vue {
    schema: Schema = [
        {
            name: 'new_password1',
            label: 'Nieuw wachtwoord',
            type: 'password',
            icon: 'mdi-key',
        },
        {
            name: 'new_password2',
            label: 'Nieuw wachtwoord herhalen',
            type: 'password',
            icon: 'mdi-key',
        },
    ];

    @Action
    addSuccess!: (msg: string) => void;

    data: any = {};
    errors: any = {};

    loading = false;
    success = false;
    error = false;


    async submit() {
        this.loading = true;
        this.success = false;
        this.error = false;
        this.errors = {};

        try {
            await mcFetch('/auth/password/change', {
                method: 'post',
                body: JSON.stringify(this.data),
            });

            this.success = true;
            this.addSuccess('Wachtwoord bijgewerkt');
        } catch (e) {
            this.error = true;
            if (e.message) {
                throw new Error('Door een onbekende fout kon dit formulier niet worden verstuurd.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
