import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inquiry } from '@/types/api';

@Component
export default class Card extends Vue {
    @Prop()
    inquiry!: Inquiry;

    get elapsed() {
        if (this.inquiry.deadline === null) {
            return false;
        }
        const deadline = new Date(this.inquiry.deadline);
        const now = new Date();
        now.setHours(0,0,0,0);

        return deadline < now;
    }
}
