import store from '@/store/store';

export default async (url: string, init?: RequestInit, xcust: boolean = true) => {
    let mcHeaders: any;

    if (init && init.headers) {
        mcHeaders = init.headers;
    } else {
        mcHeaders = {};
    }

    if (store.state.user.token) {
        mcHeaders.Authorization = 'Token ' + store.state.user.token;
    }

    const mcInit: RequestInit = {
        headers: mcHeaders,
    };

    if (init) {
        Object.assign(init, mcInit);
    } else {
        init = mcInit;
    }

    return await fetch(url, init);
};
