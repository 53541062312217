import { Component, Vue } from 'vue-property-decorator';
import { Schema } from '@/components/smartForm/types';

import SmartForm from '@/components/smartForm/smartForm.vue';
import { Me } from '@/types/api';
import { State, Action } from 'vuex-class';
import mcFetch from '@/api/mcFetch';

@Component({
    components: {
        SmartForm,
    },
})
export default class User extends Vue {
    @State((state) => state.user.user)
    user!: Me;

    @Action
    addSuccess!: (msg: string) => void;

    schema: Schema = [
        {
            name: 'name',
            label: 'Naam',
            type: 'text',
        },
        // {
        //     name: 'email',
        //     label: 'E-mailadres',
        //     type: 'e-mail',
        // },
    ];

    data: Me | null = null;
    errors: any = {};

    loading = false;
    success = false;
    error = false;

    created() {
        this.data = {
            ...this.user,
        };
    }

    async submit() {
        this.loading = true;
        this.success = false;
        this.error = false;
        this.errors = {};

        try {
            await mcFetch('/auth/me', {
                method: 'put',
                body: JSON.stringify(this.data),
            });

            this.success = true;

            this.addSuccess('Profiel bijgewerkt');
        } catch (e) {
            this.error = true;
            if (e.message) {
                throw new Error('Door een onbekende fout kon dit formulier niet worden verstuurd.');
            } else if (e.non_field_errors) {
                throw new Error(e.non_field_errors[0]);
            } else {
                this.errors = e;
            }
        } finally {
            this.loading = false;
        }
    }
}
